@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
body{
  background: #eee;
  background-image: linear-gradient(45deg, #afeadd, #817fff, #a1ebff);
  background-position: center;
  background-size: 400%;
  animation: bg-animation 5s infinite alternate;
  font-family: "Quicksand";
}
@keyframes bg-animation{
  0%{background-position: left;}
  100%{background-position: right;}
}
.navbar{
  background-color: #FFF;
  box-shadow: 0 0.5rem 3rem #F5F5F5;
  font-family: "Quicksand";
  height: 5rem;
}
.jumbotron-fluid{
  background: #c3c0ff;
  padding: 100px;
  text-align: center;
  font-family: "Quicksand";
  box-shadow: 0 0.5rem 3rem #eee;
  color: #333333;
}
.content{
  margin-top: 1rem;
}
.newsletter{
  background: #0f378d;
  padding: 80px;
  color: #fff;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 0.5rem 3rem #f3f3f3;
}
.content h3{
  font-size: 400%;
}
.content p{
  font-size: 140%;
  transform: translateZ(100)scale(.90);
}
.main{
  background-image: linear-gradient(60deg, #155497, #4053fc, #398aec);
  /*background-position: center;
  background-size: 400%;
  animation: bg-animation 5s infinite alternate;*/
  color: white;
  padding: 5rem;
}
.section{
  background-image: linear-gradient(60deg, #d8d3ff, #89b9e6, #d4e7ff);
  background-position: center;
  padding: 5em;
  background-size: 400%;
  animation: bg-animation 5s infinite alternate;
}
@keyframes bg-aniamtion{
  0%{background-position: left;}
  100%{background-position: right;}
}
.text-content{
  text-align: center;
  font-family: "Poppins";
}
.img{
  background: #FFF;
  border-radius: 100px;
  height: 100px;
  padding: 10px;
}
.imgs:hover{
  transform: scale(1.1);
  transition: all 0.2s ;
}
.link{
  text-decoration: none;
  background: #0f378d;
  padding: 20px;
  margin-top: 1rem;
  width: 100%;
  display: inline-block;
  color: #f4eeee;
  border: 3px solid #F8FEF9;
  font-size: 1.2rem;
}
.text{
  background: #4053fc;
  color: white;
  padding: 8px;
  border-radius: 20px;
}
.btns{
  background: #a1ebff;
  width: 100%;
  padding: 10px;
  font-size: 1.5rem;
}
.post{
  font-family: "Poppins";
}
.jumbotron{
  background: #c3c0ff;
  padding: 8rem;
}
.articles{
  color: #333;
  margin-top: 1.3rem;
  font-family: "Poppins";
}
.textarea{
  background-image: linear-gradient(45deg, #398aec, #4053fc, #68aefa);
  background-size: 400%;
  animation: bg-animation 5s infinite alternate;
  color: white;
  font-family: "Poppins";
}
.sectionImg{
  height: 500px;
  width: 600px;
}
@media screen and (max-width: 1080px){
  .navbar{
    height: 4em;
  }
  .text{
    font-size: 0.8em;
  }
  .image{
    margin-top: 2em;
    height: 200px;
    width: 100%;
  }
  .sectionImg{
    height: 300px;
    width: 300px;
    margin-bottom: 2em;
  }
  .competition{
    text-align: center;
  }
  .footer{
    text-align: center;
  }
}